//Functions to handle the scheduler on the appointments index view

/**
 *
 * @param text
 * @returns {string}
 * Funcion para traducir algunos textos del cuadro del kendo
 */
function translateNameView(text) {
    switch(text)
    {
        case 'Día':
            return 'day';
            break;

        case 'Semana':
            return 'week';
            break;

        case 'Mes':
            return 'month';
            break;

        case 'Agenda':
            return 'agenda';
            break;

        default:
            return text;
    }
}

/**
 * Objetivo: funcion para obtener los datos del paciente dado una cedula de identidad y colocar los datos del
 * paciente en el formulario.
 */
function getInfoPatient() {
    $.ajax({
        url: '/patients/info',
        type: 'POST',
        data: {
            patient_identification_id: $('input#patient_identification_id').val(),
        },
        async:false
    })
    .done(function(response) {
        if ( response == '' ) {
            formCita.resetValues();
        } else {
            formCita.setValues( response );
        }
    })
    .fail(function(response) {
    });
}

var formCita = {
    init: function() {
        $("input[name=patient_allergies]").on('change', function( e ) {
            //$("#patient_allergies_txt").val("");
            if ( $(this).val() == 1 ) {
                $("#div_allergie").show();
                $( "#patient_allergies_txt" ).focus();
            } else {
                $("#div_allergie").hide();
            }
        });

        //bind event to find the patient by identification
        $('input#patient_identification_id').blur(getInfoPatient);

        //apply mask to telephone numbers
        $('#patient_telephone_number').kendoMaskedTextBox({mask: "(9999) 000-0000", unmaskOnPost: true});
        $('#patient_cellphone_number').kendoMaskedTextBox({mask: "(9999) 000-0000", unmaskOnPost: true});
        $("#div_allergie").hide();

        $("#patient_height,#patient_abdominal_circumference,#patient_weight"
        ).each( function(){
            $( this ).clone().insertAfter( $(this).parent().parent() ).prop('type', 'hidden');
            var id = $(this).attr('id');
            $(this).attr('id', id+'_clone').attr('name', id+'_clone');
        });

        $("body").on('change', "input[name$='_clone']" ,
            function(){
                var valor = $(this).val();
                var old_id = $(this).attr('id');
                var new_id = old_id.substr(0,old_id.length - 6);
                $('#'+new_id).val(valor);
            });


    },
    setValues: function( values ) {
        $('#patient_email')             .val(values.email)              .prop('disabled', true);
        $('#patient_first_name')        .val(values.first_name)         .prop('disabled', true);
        $('#patient_last_name')         .val(values.last_name)          .prop('disabled', true);
        $('#patient_telephone_number')  .val(values.telephone_number)   .prop('disabled', true);
        $('#patient_cellphone_number')  .val(values.cellphone_number)   .prop('disabled', true);

        if ( values.allergies != '') {
            $("#patient_allergies_txt").val(values.allergies);
            $('input[name=patient_allergies]')[0].checked = true;
            $("#div_allergie").show();
        } else {
            $("#patient_allergies_txt").val("");
            $('input[name=patient_allergies]')[1].checked = true;
            $("#div_allergie").hide();
        }
    },
    resetValues: function() {
        $('#patient_email')             .val('').prop('disabled', false);
        $('#patient_first_name')        .val('').prop('disabled', false);
        $('#patient_last_name')         .val('').prop('disabled', false);
        $('#patient_telephone_number')  .val('').prop('disabled', false);
        $('#patient_cellphone_number')  .val('').prop('disabled', false);

        $("#patient_allergies_txt").val('');
        $('input[name=patient_allergies]')[0].checked = false;
        $('input[name=patient_allergies]')[1].checked = false;
        $("#div_allergie").hide();      
    },
    setValuesByProcedure: function( procedure ) {
        //hide or show the width and height fields based on modality (Tomografia o resonancia magnética)
        if (procedure.modality.requires_additional_fields == 1) {
            $("#div-study-validate").show();
        } else {
            $("#div-study-validate").hide();
        }

        //show procedure indication
        $("#div_procedure_indication").html("<p>" + procedure.indications + "<p/>");
    },
    loadRecentsPatients: function() {
        //load the recents patient_id into the field
        recents_patient_id.forEach( function( item ){
            $('#recents_patient_identification_id').append(
                "<option>" + item + "</option>"
            );
        });
    },
    setAllergies: function( allergies_txt ) {
        //show or hide fields based on appointment values
        if (allergies_txt == "" || allergies_txt == null) {
            $('input[name=patient_allergies]')[1].checked = true;
            $("#div_allergie").hide();
            $("#patient_allergies_txt").val("");
        } else {
            $('input[name=patient_allergies]')[0].checked = true;
            $("#div_allergie").show();
            $("#patient_allergies_txt").val(allergies_txt);
        }
    },
    validationFail: function(response) {
        var responseText = JSON.parse(response);
        var errorMessage = '';

        //hide all errors
        $('[id$="-error"]').hide();

        //parse current errors and show them below each field
        jQuery.each(responseText, function(key, value) {
            element = $('#'+key);
            errorElement = $('#'+key+'-error');
            if ( element.length > 0 ) {
                if ( errorElement.length > 0 ) {
                    errorElement.html(value[0]).show();
                } else {
                    $('#'+key).after('<span id="'+key+'-error" class="help-block error-help-block">' + value[0] + '</span>');
                }
            } else {
                errorMessage = errorMessage + value[0] + '<br>';
            }
        });

        //show error message, only used if there's a message not included below a field.
        if ( errorMessage != "" ) {
            kendo.alert(errorMessage);
        }
    }
}

/**
 *
 * @param procedures
 * @param procedure_id
 * @returns {array}
 * Objetivo: Se pasan todos los procedimientos, y el procedimiento id del que se quiere buscar.
 * Se retornan los datos del procedimiento seleccionado.
 */
function getActualProcedure(procedures, procedure_id)
{
    var procedure = procedures.filter(function(procedure){
        return procedure.id == procedure_id;
    });

    procedure = procedure[0];

    return procedure;
}
/**
 *
 * @param procedure
 * @param room_id
 * @param room
 * @returns {int}
 * Se pasa el procedimiento, el id de la sala y el objeto array sala.
 * Objetivo: Obtener la duración del procedimiento en base a la sala.
 * block_size tamaño del bloque
 * quota numero de espacios que tiene un bloque
 */
function getProcedureDuration(procedure, room_id, room) {
    var procedure_duration = procedure.rooms.filter(function(procedureRoom){
        return procedureRoom.id == room_id;
    });

    //the duration must be at least one block
    procedure_duration_block = procedure_duration[0].pivot.duration;
    if ( procedure_duration_block == 0 ) {
        procedure_duration_block = 1;
    }

    //transform duration in blocks to duration in minutes
    procedure_duration = procedure_duration_block * (room.block_size / room.quota);
    
    return procedure_duration;
}

/**
 * @param e
 * @returns {int}
 * Objetivo: funcion para retornar el numero de ocurrencias en el rango de tiempo de un evento dado
 */
function getOccurrences(e)
{
    var scheduler = $("#scheduler").getKendoScheduler();

    var occurrences = scheduler.occurrencesInRange(e.event.start, e.event.end);

    occurrences =occurrences.filter(function(occurrence){
        return occurrence.equipment_id == e.event.equipment_id;
    });

    return occurrences;
}

/**
 * @param start_date
 * @param end_date
 * @param room
 * @returns {boolean}
 * @constructor
 * Objetivo: Se valida que la hora de inicio y fin de la sala  sean menores o iguales que la hora de inicio y fin dadas.
 * Se validan son las horas, la fecha esta como random.
 */
function IsOutOfRange(start_date, end_date, room)
{
    if((new Date("2016/06/06 " + end_date.format("hh:mm A")) > new Date("2016/06/06 " + room.end_hour)) || (new Date("2016/06/06 " + start_date.format("hh:mm A")) < new Date("2016/06/06 " + room.start_hour)))
    {
        return true;
    }
    else
    {
        return false;
    }
}

/**
 * @param start_date
 * @returns {boolean}
 * @constructor
 * Objetivo: Valida que la fecha actual sea menor o igual que el nuevo evento a realizarse. Que no sea una fecha hora anterior a la fecha hora actual.
 */
function IsNotOldDate(start_date)
{
    var actual_date = moment(new Date());

    return actual_date > start_date;
}

/**
 * @param e
 * @returns {boolean}
 * Objetivo: Función para validar la existencia o no de los campos necesarios para guardar la cita (evento dado)
 */
function validFormEvent(e) {
    var errorMessage = '';

    if(!e.event.patient_identification_id) {
        errorMessage = ErrorMessageForm('required', 'patient-id');
    } else if(!e.event.patient_email) {
        errorMessage = ErrorMessageForm('required', 'email');
    } else if(!e.event.patient_first_name) {
        errorMessage = ErrorMessageForm('required', 'name');
    } else if(!e.event.patient_last_name) {
        errorMessage = ErrorMessageForm('required', 'last_name');
    } else if(!e.event.patient_telephone_number) {
        errorMessage = ErrorMessageForm('required', 'telephone_number');
    } else if(!e.event.patient_cellphone_number) {
        errorMessage = ErrorMessageForm('required', 'cellphone_number');
    } else if(e.event.procedure_contrast_study==undefined) {
        errorMessage = ErrorMessageForm('required', 'contrast-study');
    } else if (e.event.patient_allergies_check==undefined) {
        errorMessage = ErrorMessageForm('required', 'allergies');   
    } else if ((e.event.patient_allergies_check ==1) &&  (!e.event.patient_allergies_txt)) {        
        errorMessage = ErrorMessageForm('required', 'allergies');    
    
    } else if (e.event.vdiv_study_validate) {
        if (!e.event.patient_weight) {
            errorMessage = ErrorMessageForm('required', 'weight');
        } else if (!e.event.patient_height) {
            errorMessage = ErrorMessageForm('required', 'height');    
        } else if (!e.event.patient_abdominal_circumference) {
            errorMessage = ErrorMessageForm('required', 'patient_abdominal_circumference');
        }
    }

    if ( errorMessage != '' ) {
        kendo.alert(errorMessage);
        return false;
    } else {
        return true;
    }
}

/**
 *
 * @param rooms
 * @param procedures
 * @param date
 * @param view
 * Objetivo: Función para refrescar el calendario, hay que pasar todos los datos de los parametros.
 * sala, procedimiento, procedimiento de la sala, la fecha seleccionada, view (agenda, mes, semana)
 */
function refreshScheduler(rooms, procedures, date,date_end, view)
{
    $('.procedure_room').addClass('hidden');

    $('#procedure_room_' + $("#room_id_appointment").val()).removeClass('hidden');

    var appointments = [];
    var data={
        start_date:date,
        end_date:date_end
    }

    $.ajax({
            url: '/appointments/datasource',
            type: 'GET',
            async:false,
        data:data
        })
        .done(function(response)
        {
            console.log(response);
            appointments = response;

           // schedulerAppointments(rooms, appointments, procedures, date, view);
        })
        .fail(function(){
            //RefreshError();
        });
}

function buildDataSource( appointments ) {
    var room_id = $('#room_id_appointment').val();

    //Get the actual room array
    var room = json_rooms.filter(function(room){
        return room.id == room_id;
    });

    room = room[0];

    //Filter the appointments by the actual room
    appointments = appointments.filter(function(appointment){
        return appointment.room_id == room.id;
    });


    arrSrc = [];
    for(var i = 0; i < appointments.length; i++) {
        arrSrc.push({
            'id':appointments[i].id,
            'title':appointments[i].procedure.description + " [" + appointments[i].patient_first_name + " " + appointments[i].patient_last_name + "]",
            'start': new Date(appointments[i].appointment_date_start.replace(/-/g,"/")),
            'end': new Date(appointments[i].appointment_date_end.replace(/-/g,"/")),
            'observations': appointments[i].observations,
            'equipment_id': appointments[i].equipment_id,
            'procedure_id': appointments[i].procedure_id,
            'patient_identification_id': appointments[i].patient_identification_id,
            'patient_first_name': appointments[i].patient_first_name,
            'patient_last_name': appointments[i].patient_last_name,
            'patient_telephone_number': appointments[i].patient_telephone_number,
            'patient_cellphone_number': appointments[i].patient_cellphone_number,
            'patient_email': appointments[i].patient_email,
            'observations': appointments[i].observations,
            'applicant': appointments[i].applicant,
            'procedure_id': appointments[i].procedure_id,
            'patient_allergies': appointments[i].patient_allergies,
            'patient_weight': appointments[i].patient_weight,
            'patient_height': appointments[i].patient_height,
            'patient_abdominal_circumference': appointments[i].patient_abdominal_circumference,
            'procedure_contrast_study': appointments[i].procedure_contrast_study,
            'appointment_status_id': appointments[i].appointment_status_id,
        });
    }

    //Get the locked blocks
    var lock_blocks = room.avoided_blocks.split(" ");

    //Convert the locked blocks to events
    for( var j = 0; j<lock_blocks.length; j++) {
        if( lock_blocks[j] !== "" ) {
            var hours = getHours(room.block_size, room.start_hour, room.end_hour, lock_blocks[j]);

            for(var k = 1; k <= room.equipment_number; k++) {
                arrSrc.push({
                    'id': 'locked-' + room.id + '-' + j,
                    'title': BlockLocked(),
                    'start': new Date("2016/10/3 " + hours.split("-")[0]),
                    'end': new Date("2016/10/3 " + hours.split("-")[1]),
                    'recurrenceRule': 'FREQ=DAILY',
                    'equipment_id': k,
                    'block': true
                });

            }
        }
    }

    return arrSrc;
}

function schedulerAppointmentsRefresh( appointments ) {
    
    dataSrc = new kendo.data.SchedulerDataSource({
        data: buildDataSource( appointments )
    })

    var scheduler = $("#scheduler").data("kendoScheduler");
    scheduler.setDataSource( dataSrc );
    scheduler.refresh();
}

/**
 * @param rooms
 * @param appointments
 * @param procedures
 * @param date
 * @param view
 * Objetivo: Renderiza todo el calendario
 */
function schedulerAppointments(rooms, appointments, procedures, date, view) {
    //Id of the actual room
    var room_id = $('#room_id_appointment').val();

    //Get the actual room array
    var room = json_rooms.filter(function(room){
        return room.id == room_id;
    });

    room = room[0];

    dataSource = buildDataSource(appointments);

    //Array of equipment and his colors
    var equipment = [];

    var colors = [
        /*"#0079c2",
        "#a94442",
        "#203d4f",*/
        "#00a2d9",
        "#00a2d9",
        "#00a2d9",
        "#00a2d9",
    ];

    //Fill the equipment array
    for(var i = 1; i <= room.equipment_number; i++) {
        equipment.push({ text: EquipmentText() + " " + i, value: i, color: colors[i] });
    }

    //The procedures data to aggregate this values to the events
    var proceduresData = [];

    for(var i = 0; i < procedures.length; i++)
    {
        proceduresData.push({
            text: procedures[i].description,
            value: procedures[i].id,
            indication: procedures[i].indications
        });
    }

    //Remove the previous instance of the scheduler and add a new one
    $("#scheduler").remove();
    $('#room_scheduler').append('<div id="scheduler"></div>');

    //Init the scheduler
    $("#scheduler").kendoScheduler({

        date: new Date(date),
        majorTick: room.block_size, //Tamaño del bloque
        startTime: new Date("2013/6/6 " + room.start_hour),
        endTime: new Date("2013/6/6 " + room.end_hour),
        workDayStart: new Date("2016/09/23 " + room.start_hour),
        workDayEnd: new Date("2016/09/23 "  + room.end_hour),
        minorTickCount: room.quota, //Espacio en cupos del bloque
        showWorkHours: false,
        allDaySlot: false,
        views: ["day", "week", "month", "agenda"],
        editable: {
            window:{width:"40%"},
            resize: false,
            template: $("#editor").html(),

            confirmation: false,
        },
        messages: {
            editor: {
                editorTitle: appointmentMessage()
            }
        },

        dataSource: {
            data: dataSource,
        },

        //Valores para agrupar por equipo para dividir el scheduler
        group: {
            resources: ["equipment_id"]
        },

        //Valores que puede tener el evento
        resources: [
            {
                field: "appointment_status_id",
                dataSource: [
                    { value: 1, color: "#00a2d9" },
                    { value: 3, color: "#a4aeb9" }
                ],
            },
            {
                field: "equipment_id",
                name: "equipment_id",
                dataSource: equipment,
                title: "{{ ucfirst(trans('labels.equipment')) }}",
            },
            {
                field: "procedure_id",
                name: "procedure",
                dataSource: proceduresData,
                title: "{{ ucfirst(trans('labels.procedure')) }}"
            }
        ],

        add: function(e)
        {
            console.log('add');

            formCita.init();

            var procedure_id = $('#procedure_id_' + room_id).val();

            if(!procedure_id) {
                var errorMessage = errorAppointmentProcedure();
                kendo.alert(errorMessage);
                e.preventDefault();
            } else {
                var procedure = getActualProcedure(procedures, procedure_id);
                var procedure_duration = getProcedureDuration(procedure, room_id, room);
                var start_date = moment(e.event.start);
                //Calculate the event end date in order to the procedure duration
                //var end_date = moment(e.event.end);
                //e.event.end = new Date(start_date.add(procedure_duration, 'm').format("YYYY/M/D h:mm A"));
                var end_date = moment(e.event.start);
                end_date.add(procedure_duration, 'm');
                e.event.end = new Date(end_date.format("YYYY/M/D h:mm A"));

                e.event.procedure_id = procedure_id;

                //Get the number of occurrences on the range of the event
                var occurrences = getOccurrences(e);

                //Check if exists other event in the block selected
                if(occurrences.length > 0) {
                    var errorMessage = errorBusyBlock();
                    kendo.alert(errorMessage);
                    e.preventDefault();
                } else {
                    //Check if the actual date is greater than the start date of the event
                    if(IsNotOldDate(start_date)) {
                        var errorMessage = errorNotValidDate();
                        kendo.alert(errorMessage);
                        e.preventDefault();
                    } else {
                        //Check if the event overflow the capacity of the room
                        if(IsOutOfRange(start_date, end_date, room)) {
                            var errorMessage = errorInsufficientBlock();
                            kendo.alert(errorMessage);
                            e.preventDefault();
                        }
                    }
                }
            }
        },

        edit: function(e) {
            console.log('edit');

            var validator = e.container.data("kendoValidator");
            console.log(validator)



            if ( e.event.block == true || e.event.appointment_status_id == 3) {//it's a locked block or an already admitted appointment

                var errorMessage = errorEditBlock();
                kendo.alert(errorMessage);
                e.preventDefault();
            } else {//it's a new or existing appointment
                stopInterval();
                
                //find the current selected procedure
                var procedure = getActualProcedure(json_procedures, e.event.procedure_id);

                //init form values and events
                formCita.init();

                //show or hide fields based on procedure values
                formCita.setValuesByProcedure( procedure );

                if(e.event.id == 0) {//creating a new appointment
                    //load the recents patient_id into the field
                    formCita.loadRecentsPatients();
                } else { //editing an existent appointment
                    //show or hide fields based on appointment values
                    formCita.setAllergies(e.event.patient_allergies);
                }
            }
        },

        moveStart: function(e)
        {
            console.log('moveStart');
        },

        moveEnd: function(e)
        {
            console.log('moveEnd');

            if(e.event.block == true)
            {
                var errorMessage = errorMoveBlock();
                kendo.alert(errorMessage);
                e.preventDefault();
            }
            else
            {
            }
        },
        
        navigate: function() {
            setTimeout( getAppointmentsInterval, 50);
        },

        remove: function(e) {
            console.log('remove');

            if(e.event.block == true || e.event.appointment_status_id == 3) {
                var errorMessage = errorDeleteBlock();
                kendo.alert(errorMessage);
                e.preventDefault();
            } else {
                startInterval();
                kendo.prompt( reasonToDelete(), '').then(function (data) {

                    if(data != '') {
                        $.ajax({
                            url: '/appointments/delete/'+ e.event.id,
                            type: 'POST',
                            data: {
                                cancelation_reason: data,
                                cancelation_date: moment(new Date()).format("YYYY/MM/DD HH:mm:ss")
                            },
                            async:false
                        }).done(function(response)
                            {
                                if (response.hasOwnProperty('error'))
                                {
                                    kendo.alert(errorBusyBlock());
                                    e.preventDefault();
                                }
                                else
                                {
                                    e.event.id = response.id;
                                    kendo.alert(SuccessAppointmentCanceled());
                                    console.log(e);
                                }
                            })
                            .fail(function(response)
                            {
                                if(response.status == 401)
                                {
                                    kendo.alert(NotAuthorized());
                                }
                                else
                                {
                                    kendo.alert('Error: ' + response.error + '. ' + 'Message: ' + response.message);
                                }

                                e.preventDefault();
                            });
                    }
                    else
                    {
                        kendo.alert(WrongReasonToCancel());
                        e.preventDefault();
                    }
                });

            }
        },

        cancel: function(e) {
            console.log('cancel');
            startInterval();

            if(e.event.block == true) {
                e.preventDefault();
            }
        },

        save: function(e) {
            console.log('save');
            var start_date = moment(e.event.start);
            var end_date = moment(e.event.end);
            //Get the number of occurrences on the range of the event
            var occurrences = getOccurrences(e);

            var errorMessage = '';
            if( errorMessage == '' && IsOutOfRange(start_date, end_date, room)) {
                errorMessage = errorInsufficientBlock();
            }
            if( errorMessage == '' && occurrences.length > 1) {
                errorMessage = errorBusyBlock();
            }
            if( errorMessage == '' && IsNotOldDate(start_date) ) {
                //Check if the actual date is greather than the start date of the event
                errorMessage = errorNotValidDate();
            }

            if ( errorMessage != '' ) {
                kendo.alert(errorMessage);
                e.preventDefault();
            } else {
                var procedure_id = e.event.procedure_id;
                var procedure = getActualProcedure(procedures, procedure_id);
                var procedure_duration = getProcedureDuration(procedure, room_id, room);

                e.event.title = procedure.description + " [" + e.event.patient_first_name + " " + e.event.patient_last_name + " - " + e.event.patient_identification_id + "]";

                //Add new event
                if(e.event.id == 0) {
                    //Please don't delete this segment because the codes will doesn't work anymore
                    e.event.patient_email = $('#patient_email').val() == null ? e.event.patient_email : $('#patient_email').val();
                    e.event.patient_first_name = $('#patient_first_name').val() == null ? e.event.patient_first_name : $('#patient_first_name').val();
                    e.event.patient_last_name = $('#patient_last_name').val() == null ? e.event.patient_last_name : $('#patient_last_name').val();
                    e.event.patient_telephone_number = $('#patient_telephone_number').val() == null ? e.event.patient_telephone_number : $('#patient_telephone_number').val();
                    e.event.patient_cellphone_number = $('#patient_cellphone_number').val() == null ? e.event.patient_cellphone_number : $('#patient_cellphone_number').val();
                    e.event.modality_id = $("#modality_id_reception").val();
                    e.event.patient_allergies_check = $("input[name=patient_allergies]:checked").val();
                    e.event.patient_allergies =  $('#patient_allergies_txt').val();
                    e.event.patient_weight = $("#patient_weight").val();
                    e.event.patient_height = $("#patient_height").val();
                    e.event.patient_abdominal_circumference = $("#patient_abdominal_circumference").val();
                    e.event.procedure_contrast_study = $("input[name=procedure_contrast_study]:checked").val();
                    e.event.vdiv_study_validate = $("#div-study-validate").is(":visible");

                    $.ajax({
                            url: '/appointments/add',
                            type: 'POST',
                            data: {
                                appointment_date_start: moment(e.event.start).format("YYYY/MM/DD HH:mm:ss"),
                                appointment_date_end: moment(e.event.end).format("YYYY/MM/DD HH:mm:ss"),
                                appointment_status_id: 1,
                                patient_identification_id: e.event.patient_identification_id,
                                patient_first_name: e.event.patient_first_name,
                                patient_last_name: e.event.patient_last_name,
                                patient_telephone_number: e.event.patient_telephone_number,
                                patient_cellphone_number: e.event.patient_cellphone_number,
                                patient_email: e.event.patient_email,
                                observations: e.event.observations,
                                applicant: e.event.applicant,
                                modality_id: e.event.modality_id,
                                room_id: room_id,
                                procedure_id: e.event.procedure_id,
                                procedure_duration: procedure_duration,
                                equipment_id: e.event.equipment_id,
                                string_start_unique: moment(e.event.start).format("YYYY/MM/DD HH:mm:ss") + "_" + room_id + "_" + e.event.equipment_id,
                                string_end_unique: moment(e.event.end).format("YYYY/MM/DD HH:mm:ss") + "_" + room_id + "_" + e.event.equipment_id,
                                patient_allergies: e.event.patient_allergies,
                                required_wh :e.event.vdiv_study_validate,
                                patient_weight: e.event.patient_weight,
                                patient_height: e.event.patient_height,
                                patient_abdominal_circumference: e.event.patient_abdominal_circumference,
                                procedure_contrast_study: e.event.procedure_contrast_study,
                                patient_allergies_check: e.event.patient_allergies_check
                            },
                            async:false
                        })
                        .done(function(response) {
                            console.log('done');
                            startInterval();
                            if (response.hasOwnProperty('error')) {
                                e.preventDefault();

                                if ( response.error == 23000 ) {
                                    kendo.alert(errorBusyBlock());
                                } else {
                                    if ( response.hasOwnProperty('message') ) {
                                        kendo.alert( response.message );
                                    } else {
                                        kendo.alert("error");
                                    }
                                }
                            } else {
                                e.event.id = response.id;

                                kendo.alert(SuccessAppointmentCreated());
                                refreshScheduler(rooms, procedures, e.event.start, translateNameView($('#scheduler').data("kendoScheduler").view().title))

                                //add the newly created patient_identification_id (cédula) to the list of recent used ids.
                                recents_patient_id.unshift( e.event.patient_identification_id );
                            }
                        })
                        .fail(function(response) {
                            console.log('fail');
                            console.log(response);
                            console.log(response.status);
                            
                            if(response.status == 401) {
                                kendo.alert(NotAuthorized());
                            } else {
                                if(response.status == 422) {
                                    formCita.validationFail(response.responseText);
                                } else {
                                    kendo.alert('Error: ' + response.error + '. ' + 'Message: ' + response.message);
                                }
                            }

                            e.preventDefault();
                        });
                } else {
                    $.ajax({
                            url: '/appointments/edit/' + e.event.id,
                            type: 'POST',
                            data: {
                                appointment_date_start: moment(e.event.start).format("YYYY/MM/DD HH:mm:ss"),
                                appointment_date_end: moment(e.event.end).format("YYYY/MM/DD HH:mm:ss"),
                                appointment_status_id: 1,
                                patient_identification_id: e.event.patient_identification_id,
                                patient_first_name: e.event.patient_first_name,
                                patient_last_name: e.event.patient_last_name,
                                patient_telephone_number: e.event.patient_telephone_number,
                                patient_cellphone_number: e.event.patient_cellphone_number,
                                patient_email: e.event.patient_email,
                                observations: e.event.observations,
                                applicant: e.event.applicant,
                                modality_id: e.event.modality_id,
                                room_id: room_id,
                                procedure_id: e.event.procedure_id,
                                procedure_duration: procedure_duration,
                                equipment_id: e.event.equipment_id,
                                string_start_unique: moment(e.event.start).format("YYYY/MM/DD HH:mm:ss") + "_" + room_id + "_" + e.event.equipment_id,
                                string_end_unique: moment(e.event.end).format("YYYY/MM/DD HH:mm:ss") + "_" + room_id + "_" + e.event.equipment_id,
                                patient_allergies: e.event.patient_allergies_txt,
                                patient_weight: e.event.patient_weight,
                                patient_height: e.event.patient_height,
                                patient_abdominal_circumference: e.event.patient_abdominal_circumference,
                                procedure_contrast_study: e.event.procedure_contrast_study,
                            },
                            async:false
                        })
                        .done(function(response)
                        {
                            startInterval();

                            if (response.hasOwnProperty('error'))
                            {
                                kendo.alert(errorBusyBlock());
                                e.preventDefault();
                            }
                            else
                            {
                                kendo.alert(SuccessAppointmentEdited());
                                console.log(e.event);
                            }
                        })
                        .fail(function(response) {

                            if(response.status == 401)
                            {
                                kendo.alert(NotAuthorized());
                            }
                            else
                            {
                                if(response.status == 422)
                                {
                                    var responseText = $.parseJSON(response.responseText);
                                    var errorMessage = "";
                                    console.log(responseText);

                                    for(var i = 0; i < responseText.length; i++)
                                    {
                                        errorMessage = errorMessage + '\n' + responseText[i][0];
                                    }

                                    kendo.alert(errorMessage);
                                }
                                else
                                {
                                    kendo.alert('Error: ' + response.error + '. ' + 'Message: ' + response.message);
                                }
                            }

                            e.preventDefault();
                        });
                }
            }
        },

    });
}
