var video = document.querySelector("#videoElement");
var localStream;
var xinitial = 0;
var yinitial =0;
var widthCrop =0;
var heightCrop = 0;
var count_scan_file = $(".content-scan-file-take .item-img-scan-file").length;
console.log(count_scan_file);

function beginCapture() {

    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    .then(function(stream) {

        video.srcObject = stream;
        localStream = stream;
        video.play();

    })
    .catch(function(err) {

        alert(err.name + ": " + err.message);

    });


}

function resumeCapture() {
    video.play();
}

function stopCapture() {
    video.pause();
    localStream.getTracks()[0].stop();
}


function takeSnapShot() {
    $('#canvas').cropper('destroy');
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    var video = document.getElementById("videoElement");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL('image/png');

    var image = new Image();
    image.src = canvas.toDataURL("image/png", 1);
    $('#canvas').cropper({
        crop: function(e) {
        }
    });

    localStream.getTracks()[0].stop();
    $(".content-video").hide();
    $(".content-canvas-to-crop").show();
}


function cropImageTaked() {
    var img_url = $('#canvas').cropper('getCroppedCanvas').toDataURL("image/png");
    var img = $("<img id='img_crop'>");
    $(".content-img-crop").html("");
    img.attr('src',img_url);
    img.appendTo(".content-img-crop");
}

//insert image crop to form

function insertImageToForm() {

    //Element image
    var img = $("<img>");

    var content = $("<div>").addClass("col-xs-8 item-img-scan-file").attr('id','item-img-scan-file-'+count_scan_file);

    //button delete
    var anchor = $("<a>").attr({
        'type':'button',
        'data-content':'#item-img-scan-file-'+count_scan_file
    })
    .addClass('btn-delete-scan-file');

    anchor.append("<i class='glyphicon glyphicon-trash'></i>");
    anchor.on("click",function(e){
        e.preventDefault();

        this.closest(".item-img-scan-file").remove();
    });

    /////////////

    var url_img = $("#img_crop").attr('src');
    var input_file = $("<input type='hidden'>").attr('name','scanfile['+count_scan_file+']');

    count_scan_file ++;

    input_file.val(url_img);

    if(url_img){
        img.attr('src',$("#img_crop").attr('src'));
        img.addClass('col-xs-12');
        content.append(img);
        content.append(input_file);
        content.append(anchor);
        $(".content-scan-file-take").append(content);
    }

    resetModalScanFile();
    stopCapture();
}

function resetModalScanFile(action){
    action == "reset"?"":$('#scan-file-modal').modal('toggle');
    $('#canvas').cropper('destroy');
    $(".content-video").show();
    $(".content-img-crop").html("");
    $(".content-canvas-to-crop").hide();
}

// When everything starts
$(document).ready(function() {

    localStorage['count_scan_file'] = 0;

    if (Modernizr.getusermedia) { // Here we detect if the browser supports getUserMedia

        $('.odremans-start-capture').click(function() {

            beginCapture();

        });

        $('.odremans-stop-capture').click(function() {

            stopCapture();

        });

        $('.odremans-take-snapshot').click(function() {

            takeSnapShot();

        });

        $("#selectImageCrop").click(function(){cropImageTaked();});

        $("#close-take-picture").click(function(e) {
            e.preventDefault();
            resetModalScanFile();
            stopCapture();

        });

        $("#set-photo-scan-file").click(function(){insertImageToForm();});

        $("#resetSelectImageCrop").click(function(){
            resetModalScanFile("reset");
            beginCapture();
        });

        $(".btn-delete-scan-file").on("click",function(){
            var id_file = $(this).data('idfile');

            $.ajax("/patients/deletescanfile/"+id_file+"/")
            .done(function(data){
                console.log(data);
            });
            this.closest(".item-img-scan-file").remove();
        });





    } else {

        // swal("Oops...", "You are using a browser that does not support the Media Capture API", "error");
        alert("Oops..., You are using a browser that does not support the Media Capture API, error");

    }
});


//draw select area
