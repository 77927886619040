/*
 add here functions that could be used in any page
*/


/*
  select the first option of the select
*/
function selectFirst( element ) {
    element = $(element);
    //select the first option
    element[0].selectedIndex = 0;
    //force a re render
    element.selectpicker('render');
    //call the on change method
    element.change();    
}

/*
 Disable the options that doesn't match the criteria.
 When used with the attribute data-hide-disabled="true" then the options become hidden
 param field example: "data-foreign-id"
 param value: the value to create the filter criteria
*/
function selectFilterBy( element, field, value ) {
    element = $(element);

    element.children('option').each( function() {
        iValue = $(this).attr(field);
        if ( value != null && iValue == value ) {
            $(this).attr('disabled', false);
        } else {
            $(this).attr('disabled', true);
        }
    });

    element.selectpicker('refresh');
}

function selectFilterMultiBy( element, field, value ) {
    element = $(element);

    element.children('option').each( function() {
        iValue = $(this).attr(field);
        if ( value != null && iValue == value ) {
            $(this).attr('disabled', false);
            $(this).attr('selected', true);
        } else {
            $(this).attr('disabled', true);
            $(this).attr('selected', false);            
        }
    });

    element.selectpicker('refresh');
}

/*
 Removes an option from the select matching the value param
 */
function selectRemoveValue( element, value ) {
    element = $(element);

    element.children('option').each( function() {
        if ( $(this).val() == value ) {
            $(this).remove();
        }
    });

    element.selectpicker('refresh');
}